import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const PREFIX = 'CircularProgressWrapper';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  progress: `${PREFIX}-progress`,
};

const Root = styled('div')(() => ({
  [`&.${classes.wrapper}`]: {
    position: 'relative',
  },

  [`& .${classes.progress}`]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const CircularProgressWrapper = (props: {
  children: ReactNode;
  /** will only display circular progress if true */
  displayProgress: boolean;
  circularProgressSize?: string | number;
}): JSX.Element => {
  if (!props.displayProgress) {
    return <>{props.children}</>;
  }

  return (
    <Root className={classes.wrapper}>
      {props.children}
      <div className={classes.progress}>
        <CircularProgress size={props.circularProgressSize ?? 24} />
      </div>
    </Root>
  );
};
