import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AuthenticationSliceI,
  authenticationSlice,
} from '../../../store/slices/authenticationSlice';
import { Backdrop } from '@mui/material';
import { RoutesSwitch } from './RoutesSwitch';
import { TokenValidateReadDTO } from '@chillmenu/common/dist/features/login/login.admin.dto';
import { JWT } from '../../../utilities/authentication';
import { requestUrls, request } from '../../../utilities/requests';
import store from '../../../store/store';

export const AuthenticationRouter = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authChecked, setAuthChecked] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        if (!JWT.has()) {
          setAuthChecked(true);
          return;
        }

        const res = await request<TokenValidateReadDTO['Response']>({
          url: requestUrls.tokenValidate.base(),
          method: 'GET',
        });
        const authData: AuthenticationSliceI = {
          loggedIn: res.loggedIn,
          locale: res.locale,
          shopId: res.shopId,
          userId: res.shopId,
        };

        store.dispatch(authenticationSlice.actions.setAuthData(authData));

        setAuthChecked(true);

        if (!authData.loggedIn) {
          navigate('/');
          return;
        }
      } catch (err) {
        navigate('/');
        return;
      }
    })();
  }, [location]);

  if (authChecked) {
    return <RoutesSwitch />;
  } else {
    return (
      <div style={{ textAlign: 'center' }}>
        <Backdrop open={true} />
      </div>
    );
  }
};
