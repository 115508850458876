import { Button, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { DialogTitle } from './DialogTitle';
import { ResponsiveDialog } from './ResponsiveDialog';
import { Conditional } from '../Utils/Conditional';
import { CircularProgressWrapper } from '../Animations/CircularProgressWrapper';

export const ActionDialog = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  /** in case a title is needed, a raw string can be provided */
  title?: string;
  /** in case a title is needed, a locale id can be provided */
  titleLocaleId?: string;
  /** an X will appear next to the title to cancel the dialog */
  cancelIconOnTitle?: boolean;
  /** the content of the dialog */
  children: React.ReactNode;
  onSubmit: VoidFunction;
  /** on cancel callback. By default, the dialog closes */
  onCancel?: 'close' | VoidFunction;
  /** content will have dividers */
  dividers?: boolean;
  cancelAction?: {
    name: string;
    disabled?: boolean;
    /** override the default "onCancel" call */
    onClick?: VoidFunction;
    color?: React.ComponentProps<typeof Button>['color'];
  };
  submitAction: {
    name: string;
    disabled?: boolean;
    /** override the default "onSubmit" call */
    onClick?: VoidFunction;
    color?: React.ComponentProps<typeof Button>['color'];
    /** displays spinner on top of submit button if true */
    displaySpinner?: boolean;
  };
}): JSX.Element => {
  const {
    open,
    setOpen,
    onSubmit,
    title,
    cancelIconOnTitle,
    titleLocaleId,
    cancelAction,
    submitAction,
  } = props;

  let onCancel: VoidFunction = () => setOpen(false);
  if (props.onCancel && props.onCancel !== 'close') {
    onCancel = props.onCancel;
  }

  const cancelActionComponent = cancelAction && (
    <Button
      onClick={cancelAction.onClick || onCancel}
      color={cancelAction.color || 'secondary'}
      disabled={cancelAction.disabled}
    >
      {cancelAction.name}
    </Button>
  );

  const submitActionComponent = (
    <CircularProgressWrapper
      displayProgress={!!submitAction.displaySpinner}
      circularProgressSize={24}
    >
      <Button
        onClick={submitAction.onClick || onSubmit}
        color={submitAction.color || 'primary'}
        variant="contained"
        disabled={submitAction.disabled}
      >
        {submitAction.name}
      </Button>
    </CircularProgressWrapper>
  );

  return (
    <div
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          onSubmit();
        }
      }}
    >
      <ResponsiveDialog open={open} onClose={onCancel}>
        <Conditional condition={!!title || !!titleLocaleId}>
          <DialogTitle onClose={cancelIconOnTitle ? onCancel : undefined}>
            {title}
          </DialogTitle>
        </Conditional>
        <DialogContent dividers={props.dividers}>
          {props.children}
        </DialogContent>
        <DialogActions>
          {cancelActionComponent}
          {submitActionComponent}
        </DialogActions>
      </ResponsiveDialog>
    </div>
  );
};
