import { JWT } from './authentication';
import { AdminRequestUrls } from '@chillmenu/common/dist/src/requests/requests';

export const requestUrls = new AdminRequestUrls(
  process.env.REACT_APP_SERVER_BASE_URL || '',
);

export const request = async <T>(params: {
  url: string;
  method: RequestInit['method'];
  body?: Record<string, unknown>;
  misc?: Partial<RequestInit>;
}): Promise<T> => {
  const fetchData: RequestInit = {
    method: params.method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + JWT.get(),
    },
    ...params.misc,
  };
  if (params.body) {
    fetchData.body = JSON.stringify(params.body);
  }

  const fetchResult = await fetch(params.url, fetchData);

  const returnValue: T = await fetchResult.json();

  if (fetchResult.status < 200 || fetchResult.status >= 300) {
    throw new Error(JSON.stringify(returnValue));
  }

  return returnValue;
};
