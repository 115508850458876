import React from 'react';
import { styled } from '@mui/material/styles';
import { Login } from './Authentication/Login/Login';
import Paper from '@mui/material/Paper';

const PREFIX = 'Home';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '10px',
  },
}));

export const Home = () => {
  return (
    <Root className={classes.container}>
      <Paper className={classes.paper}>
        <p>
          Αναζητείτε το μενού καταστημάτων; Κάντε κλικ &nbsp;
          <a href={process.env.REACT_APP_STOREFRONT_URL}>εδώ</a>
        </p>
        <p>
          Αναζητείτε το συστήμα ελέγχου για επιχειρήσεις; Κάντε κλικ &nbsp;
          <a href={process.env.REACT_APP_DASHBOARD_URL}>εδώ</a>
        </p>
        <Login />
      </Paper>
    </Root>
  );
};
