import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { AuthenticationRouter } from '../structure/Home/Authentication/AuthenticationRouter';

const App = () => {
  return (
    <Router>
      <CssBaseline />
      <AuthenticationRouter />
    </Router>
  );
};

export default App;
