import React from 'react';
import { styled } from '@mui/material/styles';
import MuiTable from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const PREFIX = 'StyledTableCell';

const classes = {
  head: `${PREFIX}-head`,
  body: `${PREFIX}-body`,
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
};

const StyledMuiTable = styled(MuiTable)({
  [`&.${classes.table}`]: {},
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`& .${classes.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },

  [`& .${classes.body}`]: {
    fontSize: 14,
  },

  [`& .${classes.root}`]: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.root}`]: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export const StyledTable = (
  props: React.ComponentProps<typeof MuiTable>,
): JSX.Element => {
  return (
    <StyledMuiTable className={classes.table} {...props}>
      {props.children}
    </StyledMuiTable>
  );
};
