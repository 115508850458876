import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Shops } from './Shops/Shops';
import ResponsiveDrawer from '@chillmenu/common/dist/components/Drawer/ResponsiveDrawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { LogoutListItem } from '../Home/Authentication/Logout/LogoutListItem';

export const Admin = (): JSX.Element => {
  return (
    <ResponsiveDrawer
      drawerLists={
        <>
          <Divider />
          <List>
            <ListItem button key="Shops">
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText primary="Καταστήματα" />
            </ListItem>
            <LogoutListItem />
          </List>
        </>
      }
      title="Κέντρο διαχείρισης"
      label="admin panel navigation"
    >
      <Routes>
        <Route path="/shops" element={<Shops />} />
        <Route path="/*" element={<Navigate to="/shops" />} />
      </Routes>
    </ResponsiveDrawer>
  );
};
