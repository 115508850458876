import { LoginCreateOneDTO } from '@chillmenu/common/dist/features/login/login.admin.dto';
import { request, requestUrls } from '../../../utilities/requests';
import { JWT } from '../../../utilities/authentication';

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    const body: LoginCreateOneDTO['Request']['body'] = {
      email,
      password,
    };
    const res = await request<LoginCreateOneDTO['Response']>({
      url: requestUrls.login.base(),
      method: 'POST',
      body,
      misc: {
        credentials: 'include',
      },
    });

    if (res.accessToken) {
      JWT.set(res.accessToken);
      window.location.reload();
    }
  } catch (err) {
    JWT.remove();
    throw err;
  }
};

export const logout = () => {
  JWT.remove();
  window.location.reload();
};
