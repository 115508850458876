import React from 'react';
import { styled } from '@mui/material/styles';
import { ShopsTable } from './ShopsTable/ShopsTable';
import { CreateShop } from './CreateShop/CreateShop';
import { Divider } from '@mui/material';
import { getShops } from './shopRequests';

const PREFIX = 'Shops';

const classes = {
  entry: `${PREFIX}-entry`,
  rightAligned: `${PREFIX}-rightAligned`,
};

const Root = styled('div')({
  [`& .${classes.entry}`]: {
    marginBottom: '1rem',
  },
  [`& .${classes.rightAligned}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
});

export interface ShopRowInfo {
  id: string;
  name: string;
  email: string;
  identifier: string;
  shortName: string;
}
export const Shops = (): JSX.Element => {
  const [shops, setShops] = React.useState<ShopRowInfo[]>([]);
  React.useEffect(() => {
    (async () => {
      const shops = await getShops();
      setShops(shops);
    })();
  }, []);

  return (
    <Root style={{ textAlign: 'center' }}>
      <div className={[classes.entry, classes.rightAligned].join(' ')}>
        <CreateShop addShop={(shop) => setShops([shop, ...shops])} />
      </div>
      <Divider className={classes.entry} />
      <div className={classes.entry}>
        <ShopsTable shops={shops} />
      </div>
    </Root>
  );
};
