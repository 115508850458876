import { request, requestUrls } from '../../../utilities/requests';
import {
  AdminShopsCreateOneDTO,
  AdminShopsReadManyDTO,
} from '@chillmenu/common/dist/features/shops/shops.admin.dto';
import { ShopRowInfo } from '../Shops/Shops';

export const createShop = async (
  data: AdminShopsCreateOneDTO['Request']['body'],
): Promise<AdminShopsCreateOneDTO['Response']['shop']> => {
  const shopsRes = await request<AdminShopsCreateOneDTO['Response']>({
    method: 'POST',
    url: requestUrls.shops.base(),
    body: data,
  });

  return shopsRes.shop;
};

export const getShops = async (): Promise<ShopRowInfo[]> => {
  const shopsRes = await request<AdminShopsReadManyDTO['Response']>({
    method: 'GET',
    url: requestUrls.shops.base(),
  });

  return shopsRes.shops;
};
