import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthenticationSliceI {
  loggedIn: boolean;
  userId?: string | null;
  shopId?: string | null;
  locale?: string | null;
}

const initialState: AuthenticationSliceI = {
  loggedIn: false,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthData: (_state, action: PayloadAction<AuthenticationSliceI>) => {
      return action.payload;
    },
  },
});
