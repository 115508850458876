import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from '@chillmenu/common/dist/components/Table';
import { Paper } from '@mui/material';
import { ShopRowInfo } from '../Shops';

export const ShopsTable = (props: { shops: ShopRowInfo[] }) => {
  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label="shops table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Όνομα</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Κωδικός</StyledTableCell>
            <StyledTableCell>Σύντομο όνομα</StyledTableCell>
            <StyledTableCell>ID</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.shops.map((shop) => (
            <StyledTableRow key={shop.id}>
              <StyledTableCell component="th" scope="row">
                {shop.name}
              </StyledTableCell>
              <StyledTableCell>{shop.email}</StyledTableCell>
              <StyledTableCell>{shop.identifier}</StyledTableCell>
              <StyledTableCell>{shop.shortName}</StyledTableCell>
              <StyledTableCell>{shop.id}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};
