import React from 'react';
import { Home } from '../Home';
import { Admin } from '../../Admin/Admin';
import store from '../../../store/store';

export const RoutesSwitch = () => {
  const loggedIn = store.getState().authentication?.loggedIn;

  if (!loggedIn) {
    return <Home />;
  }

  return <Admin />;
};
