import React from 'react';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { ShopRowInfo } from '../Shops';
import { createShop } from '../shopRequests';
import { ActionDialog } from '@chillmenu/common/dist/components/Dialog/ActionDialog';
import { LocaleCode } from '@chillmenu/common/dist/src/internationalization';

export const CreateShop = (props: {
  addShop: (shop: ShopRowInfo) => void;
}): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const defaultFormValues = {
    shopName: '',
    userName: '',
    emailAddress: '',
    shortName: '',
    password: '',
    phoneNumber: '',
    locale: 'el-GR' as LocaleCode,
  };
  const [formValues, setFormValues] = React.useState(defaultFormValues);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const shop = await createShop({
        names: {
          shop: {
            locale: formValues.locale,
            name: formValues.shopName,
          },
        },
        shop: {
          shortName: formValues.shortName,
          preferredLocales: [formValues.locale],
          contactEmail: formValues.emailAddress,
          phoneNumber: formValues.phoneNumber,
        },
        user: {
          email: formValues.emailAddress,
          locale: formValues.locale,
          name: formValues.userName,
          password: formValues.password,
        },
      });

      props.addShop({
        id: shop.id,
        identifier: shop.identifier,
        name: formValues.shopName,
        email: formValues.emailAddress,
        shortName: formValues.shortName,
      });

      setFormValues(defaultFormValues);
      closeDialog();
    } catch (err) {
      alert('shop creation failed');
    } finally {
      setIsSubmitting(false);
    }
  };

  const onInputChange = (
    event: { target: { value: unknown } },
    key: keyof typeof formValues,
  ) => {
    setFormValues({
      ...formValues,
      [key]: event.target.value as string,
    });
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={openDialog}>
        ΔΗΜΙΟΥΡΓΙΑ ΚΑΤΑΣΤΗΜΑΤΟΣ
      </Button>
      <ActionDialog
        open={open}
        setOpen={setOpen}
        title="Δημιουργία καταστήματος"
        onSubmit={onSubmit}
        submitAction={{
          name: 'ΔΗΜΙΟΥΡΓΙΑ',
          disabled: isSubmitting,
        }}
        cancelAction={{
          name: 'ΑΚΥΡΩΣΗ',
          disabled: isSubmitting,
        }}
      >
        <form
          noValidate
          autoComplete="off"
          style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              id="shop-name"
              label="Όνομα Καταστήματος"
              type="text"
              value={formValues.shopName}
              onChange={(e) => onInputChange(e, 'shopName')}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              id="shop-short-name"
              label="Σύντομο όνομα καταστήματος"
              type="text"
              value={formValues.shortName}
              onChange={(e) => onInputChange(e, 'shortName')}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              autoComplete="new-email"
              id="email"
              label="Διεύθυνση email"
              type="email"
              value={formValues.emailAddress}
              onChange={(e) => onInputChange(e, 'emailAddress')}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              autoComplete="new-username"
              id="user-name"
              label="Ονοματεπώνυμο χρήστη"
              type="text"
              value={formValues.userName}
              onChange={(e) => onInputChange(e, 'userName')}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              autoComplete="new-password"
              id="password"
              label="Κωδικός χρήστη"
              type="password"
              value={formValues.password}
              onChange={(e) => onInputChange(e, 'password')}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              autoComplete="off"
              label="Αριθμός τηλεφώνου"
              type="text"
              value={formValues.phoneNumber}
              onChange={(e) => onInputChange(e, 'phoneNumber')}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <Select
              variant="standard"
              id="locale"
              value={formValues.locale || ''}
              onChange={(e) => onInputChange(e, 'locale')}
              style={{ marginTop: '0.5rem' }}
            >
              <MenuItem value="el-GR">Ελληνικά</MenuItem>
              <MenuItem value="en-US">Αγγλικά</MenuItem>
            </Select>
          </FormControl>
        </form>
      </ActionDialog>
    </div>
  );
};
