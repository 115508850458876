import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store/store';
import { StyledEngineProvider } from '@mui/material/styles';
import { ChillMenuThemeProvider } from '@chillmenu/common/dist/chillmenu-components/Theme';

ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ChillMenuThemeProvider>
          <App />
        </ChillMenuThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
);

serviceWorker.unregister();
